import axios from 'axios'
import { get, post, put } from '../utils/request'
axios.prototype.get = get
axios.prototype.post = post
axios.prototype.put = put

export default {
    // 登录
    login(data) {
        return axios.post('user/login', data)
    },
    // 备赛欧静默登录
    loginkto(data) {
        return axios.post('user/kto', data)
    },
    //使用验证码登录获取验证码
    smslogin(data) {
        return axios.post('user/sms/login', data)
    },
    // 注册账号获取验证码
    smsregist(data) {
        return axios.post('user/sms/regist', data)
    },
    //忘记密码获取验证码
    smsresetpwd(data) {
        return axios.post('user/sms/resetpwd', data)
    },
    //忘记密码
    resetpwd(data) {
        return axios.post('user/sms/resetpwd', data)
    },
    // 重置密码
    czresetpwd(data) {
        return axios.post('user/resetpwd', data)
    },
    //注册
    regist(data) {
        return axios.post('user/regist', data)
    },
    //首页数据接口
    indexPage(data) {
        return axios.post('index', data)
    },
    //首页数据接口
    indexPageindex(data) {
        return axios.post('index/page', data)
    },
    //详情页
    preview(data) {
        return axios.get('preview', data)
    },
    //支付购买
    orderbuy(data) {
        return axios.post('order/buy', data)
    },
    //用户支付下单接口
    ordertrade(data) {
        return axios.post('order/trade', data)
    },
    //用户下单购买接口
    orderpay(data) {
        return axios.post('order/pay', data)
    },
    orderreturn(data) {
        return axios.post('order/return', data)
    },
    //支付订单查询接口
    orderquery(data) {
        return axios.get('order/query', data)
    },
    //详情页下载接口
    dl(data) {
        return axios.post('dl', data)
    },
    //详情页推荐
    recomm(data) {
        return axios.get('recomm', data)
    },
    //添加取消收藏
    collectionlike(data) {
        return axios.post('like', data)
    },
    //搜索页目录
    catalog(data) {
        return axios.post('search/catalog', data)
    },
    //热门搜索词接口
    searchtop(data) {
        return axios.get('search/top', data)
    },
    //分页搜索
    searchkey(data) {
        return axios.post('search/key', data)
    },
    //我的下载PPT
    mydownload(data) {
        return axios.get('my/download', data)
    },
    //我的收藏ppt
    mylike(data) {
        return axios.get('my/like', data)
    },
    //电子卡查询接口
    myecardquery(data) {
        return axios.post('my/ecard/query', data)
    },
    //电子卡绑定接口
    myecardbind(data) {
        return axios.post('my/ecard/bind', data)
    },
    //修改密码
    changepw(data) {
        return axios.post('my/changepw', data)
    },
    //查询个人信息
    myinfo(data) {
        return axios.post('my/info', data)
    },
    //更新个人信息
    myupdate(data) {
        return axios.post('my/update', data)
    },
    //首页轮播图
    indexAd(data) {
        return axios.get('index/ad', data)
    },
    //获取首页评论
    indexMsg(data) {
        return axios.get('index/msg', data)
    },
    //获取首页评论
    indexMsgadd(data) {
        return axios.post('index/msgadd', data)
    },
    indexVtwo(data) {
        return axios.post('/v2/index', data)
    },
    //获取首页评论
    indexComment(data) {
        return axios.get('v2/index/msg', data)
    },
    // 首页轮播图
    // v2/ index / ad
    indexSwiper(data) {
        return axios.get('v2/index/ad', data)
    },
    // 分页
    searchkeyHome(data) {
        return axios.post('v2/page', data)
    },


}